import { Routes } from '@angular/router';

export const baseRoutes: Routes = [
    {
        path: 'v3/login/resetPassword',
        loadChildren: () => import('../../pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
    },
    {
        path: 'remove',
        loadChildren: () => import('../../pages/login-page/login-page.module').then(m => m.LoginPageModule)
    },
    // {
    //     path: 'enrollment',
    //     loadChildren: () => import('../../pages/scan-page/scan-page.module').then(m => m.ScanPageModule)
    // }
] 