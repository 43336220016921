import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeleteUserService {

  baseUri = environment.DELETE_ACCOUNT

  constructor(
    private http: HttpClient
  ) { }

  delete(token: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.delete<any>(`${this.baseUri}/delete`, { headers }).pipe(
      tap()
    )
  }
}
