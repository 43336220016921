<div class="container white-bg" *ngIf="!success">
    <div class="card-form row start h-90vh">
        <p-card>
            <div>
                <img class="logo" [src]="iconUrl" alt="" />
            </div>
            <div class="f-24px fw-700 lh-32px f-bluish text-position">
                Deseja realmente remover sua conta?
            </div>
            <div class="pt-22px f-20px fw-500 lh-26px f-bluish text-position">O que a exclusão da sua conta significa?
            </div>
            <form #passwordForm="ngForm">
                <div class="f-18px fw-400 lh-20px pt-12px f-gray-purple text-position">
                    <label>Você perderá todos os dados e conteúdos dessa conta, como histórico de recompensas
                        resgatadas, pontuação
                        adquirida e a possibilidade de continuar participante do <span class="f-bluish fw-700">Programa
                            so+ma</span>.
                    </label>
                    <label>Não será possível ver a sua pontuação existente, ou resgatar alguma recompensa depois dessa
                        exclusão.
                    </label>
                    <label>Você perderá toda a pontuação existente, mesmo que você reative a sua conta no futuro.
                    </label>
                </div>
                <div class="row pt-40px">
                    <div class="col">
                        <button class="btn btn-default-primary" (click)="deleteUser()">Deletar conta
                        </button>
                    </div>
                </div>
            </form>
        </p-card>
    </div>
</div>

<div class="container white-bg" *ngIf="success">
    <div class="card-form row start h-90vh">
        <p-card>
            <div class="col text-position">
                <div class="row pt-26px">
                    <div class="col">
                        <img src="../../../../assets/icons/check.svg" alt="" />
                    </div>
                </div>
                <div class="row pt-26px">
                    <div class="col">
                        <span class="f-24px f-bold">Conta removida com sucesso.</span>
                    </div>
                </div>
            </div>
        </p-card>
    </div>
</div>

<div *ngIf="loading">
    <app-loading [loading]="loading"></app-loading>
</div>