import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CardModule } from 'primeng/card';

import { HttpClientModule } from '@angular/common/http';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DeleteUserComponent } from './soma-reset-password-app/pages/delete-user/delete-user.component';
import { LoginPageComponent } from './soma-reset-password-app/pages/login-page/login-page.component';
import { ResetPasswordComponent } from './soma-reset-password-app/pages/reset-password/reset-password.component';
import { LoadingComponent } from './soma-reset-password-app/shared/components/loading/loading.component';
import { InitService } from './soma-reset-password-app/services/init.service';
import { WINDOW_PROVIDERS } from './soma-reset-password-app/shared/window.token';

function initFactory(initService: InitService) {
  return () => initService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    ResetPasswordComponent,
    LoadingComponent,
    DeleteUserComponent,
    LoginPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CardModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#FFA125',
      secondaryColour: '#6D4976',
      tertiaryColour: '#ffffff',
    }),
  ],
  providers: [
    [{
      provide: APP_INITIALIZER,
      useFactory: initFactory,
      deps: [InitService],
      multi: true
    }],
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
