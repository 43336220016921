import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  constructor(private toastr: ToastrService,
    private loginService: LoginService) {

  }
  ngOnInit(): void {
  }

  password: string = "";
  username: string = "";
  code: string = "";
  loading: boolean = false;
  token: string = "";
  @Input() success: boolean = false;

  login() {
    const body = {
      consumerToken: environment.auth_params.consumerToken,
      username: this.username,
      password: this.password
    };

    this.loginService.login(body).subscribe(
      (res) => {
        this.loading = false;
        this.success = true;
        this.token = res.token;
      },
      (err) => {
        this.loading = false;
        if (err.error !== null && err.error.messages !== undefined) {
          this.toastr.error(err.error.messages[0], 'Ops');
        }
        else {
          this.toastr.error('Usuário ou senha inválidos', 'Ops!');
        }
      });
  }
}
