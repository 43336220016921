import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TenantService } from './soma-reset-password-app/services/tenant.service';
import { InitService } from './soma-reset-password-app/services/init.service';
import { TenantConfiguration } from './soma-reset-password-app/shared/interfaces/tenantConfiguration.interface';
import { TENANT } from './soma-reset-password-app/shared/interfaces/tenant.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'so+ma Vantagens';

  isReset: boolean = false;
  isDeleteAccount: boolean = false;
  isEnrollment: boolean = false;
  tenantConfiguration!: TenantConfiguration;

  constructor(private router: Router, private initService: InitService) {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          if (router.url.includes('resetPassword')) {
            this.isReset = true
          }
          else if (router.url.includes('deleteAccount')) {
            this.isDeleteAccount = true
          }
          else {
            this.isEnrollment = true;
          }
        }
      }
    )
  }

  ngOnInit(): void {
    this.initializeApp();
  }

  initializeApp() {
    this.setTheme(this.initService.tenantConfigurations);
  }

  setTheme(tenantConfiguration: TenantConfiguration) {
    document.documentElement.style.setProperty('--color-primary', tenantConfiguration.primaryColor);
    document.documentElement.style.setProperty('--color-secondary', tenantConfiguration.secondaryColor);
    document.documentElement.style.setProperty('--color-tertiary', tenantConfiguration.tertiaryColor);
    document.documentElement.style.setProperty('--color-button-text', tenantConfiguration.buttonTextColor);

    if (tenantConfiguration.imageUrl != '')
      document.documentElement.style.setProperty('--image-url', `url('${tenantConfiguration.imageUrl}')`);

    this.tenantConfiguration = tenantConfiguration;

    TENANT.ID = this.tenantConfiguration.tenantId;
    TENANT.TENANT_NAME = this.tenantConfiguration.name;
  }

}
