import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  baseUri = environment.TOKEN_BASE

  constructor(
    private http: HttpClient
  ) { }

  login(body: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('X-Consumer-Id', environment.auth_params.xConsumerId)
      .set('Content-Type', 'application/json')
      .set('Accept', '*/*')
      .set('Accept-Encoding', 'gzip, deflate, br')
      .set('Connection', 'keep-alive')

    return this.http.post<any>(`${this.baseUri}/user`, body, { headers })
      .pipe(tap());
  }
}
