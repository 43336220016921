import { Inject, Injectable } from "@angular/core";
import { tap } from "rxjs";
import { TenantService } from "./tenant.service";
import { WINDOW } from "../shared/window.token";
import { TenantConfiguration } from "../shared/interfaces/tenantConfiguration.interface";

@Injectable({
    providedIn: 'root'
})
export class InitService {
    tenantConfigurations!: TenantConfiguration;

    constructor(@Inject(WINDOW) private window: Window, private tenantService: TenantService) { }
    init() {
        let domain = this.window.location.host;
        return this.tenantService.getTenantConfigurationsByDomain(domain).pipe(tap((config) => (this.tenantConfigurations = config)));
    }
}