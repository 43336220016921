<div class="container white-bg" *ngIf="!success">
    <div class="card-form row start h-90vh">
        <p-card>
            <div>
                <img class="logo" src="../../../../assets/images/header-logo.svg" alt="" />
            </div>
            <div class="f-24px fw-700 lh-32px f-bluish text-position">
                Acesse sua conta para ser removida.
            </div>
            <div class="f-14px fw-400 lh-20px pt-12px f-gray-purple text-position">
                <span>Entre na sua conta para confirmar a remoção.</span>
            </div>
            <form #passwordForm="ngForm">
                <div class="pt-40px">
                    <div class="col-12">
                        <label class="f-black fw-400 pt-14px lh-20px">Usuário</label>
                        <input [(ngModel)]="username" class="soma-filed f-gray-purple" name="username"
                            placeholder="Digite seu CPF/CNPJ, Email ou Telefone" />
                    </div>
                </div>
                <div class="pt-22px">
                    <div class="col-12">
                        <label class="f-black fw-400 pt-14px lh-20px">Senha</label>
                        <input [(ngModel)]="password" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                            maxlength="6" inputmode="numeric" type="password" placeholder="Digite sua senha"
                            class="soma-filed f-gray-purple" name="password" />
                    </div>
                </div>
                <div class="row pt-40px">
                    <div class="col">
                        <button class="btn btn-default-primary" [disabled]="password!.length < 6 || username === ''"
                            (click)="login()">Entrar
                        </button>
                    </div>
                </div>
            </form>
        </p-card>
    </div>
</div>
<div class="container white-bg" *ngIf="success">
    <app-delete-user [token]="token"></app-delete-user>
</div>

<div *ngIf="loading">
    <app-loading [loading]="loading"></app-loading>
</div>