<div class="container white-bg" *ngIf="!success">
    <div class="card-form row start h-90vh">
        <p-card>
            <div>
                <img class="logo" [src]="iconUrl" />
            </div>
            <div class="f-24px fw-700 lh-32px f-bluish text-position">
                Agora cadastre sua nova senha!
            </div>
            <div class="f-14px fw-400 lh-20px pt-12px f-gray-purple text-position">
                <span>Digite nos campos abaixo sua nova senha.</span>
            </div>
            <form #passwordForm="ngForm">
                <div class="pt-40px">
                    <div class="col-12">
                        <label class="f-black fw-400 pt-14px lh-20px">Nova senha</label>
                        <input [(ngModel)]="password" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                            maxlength="6" inputmode="numeric" type="password" placeholder="••••••"
                            class="soma-filed f-gray-purple" name="password" />
                    </div>
                    <div class="pt-6px">
                        <span class="f-gray-purple fw-400 f-12px lh-18px">São 6 caracteres numéricos.</span>
                    </div>
                    <div class="pt-22px">
                        <label class="f-black fw-400 f-14px lh-20px">Repita sua senha*</label>
                        <input class="soma-filed f-gray-purple"
                            onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="6"
                            inputmode="numeric" type="password" placeholder="••••••" [(ngModel)]="confirmPassword"
                            name="confirmPassword" />
                    </div>
                </div>
                <div class="row pt-24px center" *ngIf="
                            password?.length === 6 &&
                            confirmPassword?.length === 6 &&
                            password !== confirmPassword">
                    <div class="col">
                        <span class="f-14px f-red">
                            Ah não, as senhas não são iguais, verifique novamente.
                        </span>
                    </div>
                </div>
                <div class="row pt-40px">
                    <div class="col">
                        <button class="btn btn-default-primary"
                            [disabled]="password!.length < 6 || confirmPassword!.length < 6 || password !== confirmPassword"
                            (click)="reset()">Criar
                            nova senha
                        </button>
                    </div>
                </div>
            </form>
        </p-card>
    </div>
</div>

<div class="container white-bg" *ngIf="success">
    <div class="card-form row start h-90vh">
        <p-card>
            <div class="col text-position">
                <div class="row pt-26px">
                    <div class="col">
                        <img src="../../../../assets/icons/check.svg" alt="" />
                    </div>
                </div>
                <div class="row pt-26px">
                    <div class="col">
                        <span class="f-24px f-bold">Nova senha cadastrada com sucesso.</span>
                    </div>
                </div>
                <div class="row pt-24px">
                    <div class="col">
                        <button class="btn btn-default-primary" (click)="goTo()" routerLinkActive="router-link-active">
                            Acessar o aplicativo
                        </button>
                    </div>
                </div>
            </div>
        </p-card>
    </div>
</div>

<div *ngIf="loading">
    <app-loading [loading]="loading"></app-loading>
</div>