import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  baseUriForgot = environment.FORGOT_BASE

  constructor(
    private http: HttpClient
  ) { }

  resetPassword(body: any): Observable<any> {
    return this.http.post<any>(`${this.baseUriForgot}/resetPassword`, body).pipe(
      tap()
    )
  }
}
