import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResetPasswordService } from '../../services/reset-password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private resetPasswordService: ResetPasswordService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => { this.userId = params['userId'], this.code = params['code'] })
  }

  userId: string = "";
  password: string = "";
  confirmPassword: string = "";
  code!: string;
  loading: boolean = false;
  @Input() success = false;
  @Input() iconUrl: string = '';

  ngOnInit(): void {
  }

  reset() {
    this.loading = true;

    const body = {
      userId: this.userId,
      password: this.password,
      confirmPassword: this.confirmPassword,
      code: this.code
    };

    this.resetPasswordService.resetPassword(body).subscribe(
      (res) => {
        this.loading = false;
        this.success = true;
      },
      (err) => {
        this.loading = false;
        if (err.error !== null && err.error.messages.length > 0) {
          this.toastr.error(err.error.messages[0], 'Ops');
        } else {
          this.toastr.error('Houve um erro inesperado!', 'Ops!');
        }
      });
  }

  goTo() {
    window.open('https://smvt.cc/app', '_self');
  };
}
