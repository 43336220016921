import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DeleteUserService } from '../../services/delete-user.service';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent implements OnInit {

  constructor(private deleteUserService: DeleteUserService,
    private toastr: ToastrService,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => { this.userId = params['userId'], this.code = params['code'] })
  }

  ngOnInit(): void {
  }

  userId: string = "";
  code!: string;
  loading: boolean = false;
  @Input() iconUrl: string = '';
  @Input() success = false;
  @Input() token: any;

  deleteUser() {
    this.loading = true;
    this.deleteUserService.delete(this.token).subscribe(
      (res) => {
        this.loading = false;
        this.success = true;
      },
      (err) => {
        this.loading = false;
        if (err.error !== null && err.error.messages.length > 0) {
          this.toastr.error(err.error.messages[0], 'Ops');
        } else {
          this.toastr.error('Houve um erro inesperado!', 'Ops!');
        }
      });
  }
}
